import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import { getItem } from '@/utils/storage'
Vue.use(VueI18n)
export const i18n = new VueI18n({
  // locale: getItem('lang') == null ? 'TH' : getItem('lang'), // 语言标识
  locale: 'TH', // 语言标识
  messages: {
    ZH: require('./zh.js'),
    TH: require('./th.js'),
    VN: require('./vn.js')
  }
})

module.exports = {
  HOME: 'muka depan',
  WALLET: 'dompet',
  SERVICE: 'khidmat Pelanggan',
  MY: 'saya',
  APPLYMONEY: 'Amaun permohonan (ringgit malaysia)',
  AWAITJIE: 'Jumlah yang perlu dibayar balik(ringgit malaysia)',
  UNIT: 'ringgit malaysia',
  BORRORWMONEYTIME: 'Tempoh pinjaman',
  EVERYISSUE: 'Bayaran balik setiap tempoh',
  DAYRATE: 'kadar faedah bulanan',
  MOTHLYINTEREST: 'faedah bulanan',
  CONSENT: 'setuju',
  AGREEMENTENT: '《perjanjian pinjaman》',
  ATONECBORROWMONEY: 'Pinjam sekarang',
  CONSENTPROTOCOL: 'Sila setuju dan semak perjanjian itu',
  CONSENTBORROWMESSAGE: 'Sahkan maklumat pinjaman',
  BORRORWMONEY: 'Jumlah pinjaman',
  ACCOUNTBANK: 'Bank deposit',
  PAYEEBOX: 'Nombor akaun pembayaran',
  SUBMITBORROWTEXT: 'Hantar pesanan pinjaman',
  PLEACHECKPROTOCOL: 'Sila semak perjanjian ini',
  MONTHS: 'bulan',
  USERNAME: 'nama pengguna',
  PASSWORD: 'password',
  SUBMIT: 'serahkan',
  CHECKUSERNMAE: 'Nama pengguna tidak boleh kosong',
  CHECKPASSWORD: 'password tidak boleh kosong',
  REGISTER: 'mendaftar',
  FORGET: 'lupa password',
  NOTCHINA: 'Tidak boleh menggunakan bahasa Cina',
  IMMEATDILEY: 'cas semula',
  CANCEL: 'Batal',
  RECHARGEMONEY: 'jumlah cas',
  WITHDRAWDEPOSIT: 'Jumlah Pengeluaran',
  WITHDRAWDEPOSITPASSWORD: 'pengeluaran password',
  INPUTWITHDRAWDEPOSITPASSWORD: 'sila masukan password pengeluaran',
  WITHDRAWDEPOSITMONEYNULL: 'Jumlah pengeluaran tidak boleh kosong',
  WITHDRAWDEPOSITNULL: 'password pengeluaran tidak boleh kosong',
  MYDATA: 'Profil saya',
  MYBORROW: 'pinjaman saya',
  MYREFUND: 'bayaran balik saya',
  OPENVIP: 'Aktifkan VIP',
  CHANGEPASSWORD: 'tukar password',
  LOGOUT: 'log keluar',
  MSUTDATAFULL: 'Berikut adalah maklumat yang diperlukan sebelum anda boleh memohon pinjaman',
  IDENTITYDATA: 'Maklumat Identiti',
  UNDERSTANDBASICINFORMATION: 'Beritahu kami keadaan asas anda',
  DATAMESSAGE: 'Maklumat Profil',
  RECEIPTBANKCARD: 'Kad bank resit',
  PHONENUMVERIFY: 'Pengesahan nombor mudah alih',
  VERIFYSELFPHONENUM: 'Sahkan nombor telefon bimbit anda sendiri',
  INCREASETHEPASSRATE: 'Berikut ialah maklumat tambahan pilihan yang boleh meningkatkan kuota dan peluang anda untuk lulus semakan:',
  FURTHERINFORMATION: 'maklumat tambahan',
  REVIEWTHEPASSRATE: 'Tingkatkan peluang anda untuk lulus semakan',
  FILLED: 'Diisi',
  FILLINTRUEINFORMATION: 'Isikan maklumat yang benar dan sah dan semakan akan diluluskan.~',
  NAME: 'nama',
  IDCARDNUMBER: 'nombor kad pengenalan',
  UPLOADIDPHOTO: 'muatnaik gambar',
  IDFRONTPHOTO: 'Gambar depan kad pengenalan',
  IDONTHEBACKPHOTO: 'Gambar belakang kad pengenalan',
  HANDTAKEIDPHOTO: 'Gambar kad pengenalan di tangan',
  ENTITYNAME: 'nama syarikat',
  POSITION: 'kedudukan',
  ENTITYPHONE: 'telefon kerja',
  WORKAGE: 'tempoh perkhidmatan',
  CURRENTRESIDENCEDETAILS: 'Alamat terperinci kediaman semasa',
  CONTACTOFIMMEDIATEFAMILY: 'Hubungan keluarga terdekat',
  CONTACTNAME: 'nama',
  CONTACTPHONE: 'Nombor telefon',
  CONTACTRELATION: 'hubungan',
  OTHER: 'hubungan Kenalan lain',
  TAKECARDNAME: 'Nama pemegang kad',
  TAKEID: 'Kad pengenalan pemegang kad',
  BANKNAME: 'nama bank',
  BANKCRAD: 'Akaun bank',
  BANKCRADSELF: 'Peringatan hangat: Kad bank yang diisi mestilah kad debit (kad deposit) atas nama saya。',
  INPUTBANKCARDNUMBER: 'Sila masukkan nombor kad bank anda',
  SAVEDATA: 'Tambah maklumat',
  BORRORWDETAIL: 'Butiran pinjaman',
  BORRORWPLAN: 'Kemajuan pinjaman',
  SUBMITSUCCEED: 'dihantar dan telah berjaya',
  ARRIVEMONEYSUCCEED: 'Ketibaan yang berjaya',
  ORDERFORMNUM: 'nombor pesanan',
  REFUNDSTATE: 'Arahan pembayaran balik',
  NEEDNOTBORRORW: 'Tak perlu bayar balik！',
  CURRENTMONTHNEEDNOTBORRORW: 'Anda tiada bil untuk dibayar balik bulan ini',
  NOTE: 'Pengesahan SMS',
  NOTENUM: 'Kod pengesahan SMS',
  NWESPASSWORD: 'password baru',
  INPUTPHONE: 'Sila masukkan nombor telefon',
  INPUTNOTE: 'Sila masukkan kod pengesahan SMS',
  INPUTNEWSPASSWORD: 'silap tetapkan 6-16 digit nombor password',
  CONFIRM: 'pasti',
  PLEAINPUTNAME: 'Sila taip nama anda',
  PLEAINPUTID: 'Sila masukkan nombor ID anda',
  PLEAENTITYNAME: 'alamat tempat kerja',
  PLEAPOSITION: 'sila isi kedudukan kerja',
  PLEAENTITYPHONE: 'Sila masukkan nombor telefon kerja anda',
  PLAEAWORKAGE: 'Sila masukkan umur bekerja',
  PLEACURRENTRESIDENCEDETAILS: 'Sila masukkan alamat kediaman semasa anda',
  PLEACONTACTRELATION: 'Contohnya: ibu bapa anak',
  BORRORSERIALBUNBER: 'Nombor pinjaman',
  TOTAILMONEY: 'jumlah semua sekali',
  RECHARGEMONEYNONTNULL: 'Jumlah cas semula tidak boleh kosong',
  RECHARGE: 'Butiran cas semula',
  UPLOADVOUCHER: 'muat naik sijil',
  UPLOADONEPROTO: 'Muat naik gambar',
  USERREGISTER: 'Pendaftaran pengguna',
  FORGETPASSWORD: 'Dapatkan password',
  NOTNULL: 'Tidak boleh kosong',
  LENGTH10: 'Panjangnya tidak boleh kurang daripada 10',
  REGISTEROK: 'pendaftaran berjaya',
  LOGINOK: 'berjaya',
  AWAIT: 'Menunggu',
  THEACCOUNTBALANCE: 'Baki akaun',
  UNFILLED: 'tak terisi',
  UNITADDRESS: 'Alamat unit',
  INPUTUNITADDRESS: 'Sila masukkan alamat kerja anda',
  OKBORRORW: 'Berjaya meminjam',
  PLEAMESSAGECOMPLETE: 'Sila tulis maklumat dengan lengkap',
  WORKCERTIFICATE: 'sijil pekerjaan',
  INCOMEPROOF: 'Bukti pendapatan',
  NOBRORROW: 'pinjaman ditolak',
  MYWITHDRAWDEPOSIT: 'pengeluaran deposit saya',
  NOMYWITHDRAWDEPOSIT: 'Tiada maklumat pengeluaran deposit',
  MYWITHDRAWDEPOSITIME: 'Masa pengeluaran deposit',
  MYWITHDRAWDEPOSITMONEY: 'Jumlah Pengeluaran deposit',
  MYWITHDRAWDEPOSITDETAILS: 'Butiran pengeluaran deposit',
  VIP: 'ahli',
  LINK: 'link',
  STATUS: 'status',
  MESSAGE: 'SMS',
  MYWITHDRAWDEPOSILONG: 'pengeluaraan kemajuan',
  LOADAPP: 'download APP',
  LIJITIXIAN: 'Tarik balik serta merta',
  TIXIANID: 'Nombor pengeluaran',
  WARMPROMPT: 'Nombor pengeluaran',
  CONTACTME: 'hubungi saya',
  QUESTION: 'jawab soalan',
  TOFAILURE: 'Gagal sampai',
  WENXINGTISHI: 'Peringatan hangat menunjukkan bahawa semakan dalam semakan. Daftar sebagai ahli untuk mempercepatkan semakan.',
  OPENVVIP: 'menyertai keahlian',
  SEND_SMS: 'Hantar kod pengesahan sms',
  CUSTOMERSERVICECENTER: 'Khidmat Pelanggan',
  BORROWINGTIME: 'Masa meminjam',
  IMGNOT: 'Gambar tidak boleh kosong',
  PLEALOGIN: 'sila log masuk',
  Pleasereviewtheprotocolcarefully: 'Sila baca perjanjian dengan teliti',
  Lookatthecontract: 'Lihat kontrak',
  FILLINALL: 'Sila isi semua',
  WDNUMBER: 'Kod pengeluaran',
  PWDWDNUMBER: 'Sila masukkan kod pengeluaran',
  EDITTHEAMOUNT: 'Edit jumlah',
  THENEWPASSWORD: 'Sila masukkan password baharu',
  MODIFYTHESUCCESS: 'Berjaya diubah suai',
  MODIFYTHEFAILURE: 'gagal mengedit',
  CONTACTCUSTOMERSERVICE: 'Hubungi Khidmat Pelanggan',
  TIX: 'pengeluaran tunai',
  CHECKPASSWORDRe: 'sahkan password',
  TWICE: 'Kata laluan yang dimasukkan dua kali tidak sama',
  HT: 'kontrak'
}
